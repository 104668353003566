import React from "react";

//Style
import Styles from "./ExpectedPriceAndBidNow.module.scss";

//Components
import Flex, { VerticalFlex } from "@vahak/core-ui/dist/layout/Flex";
import Typography from "@vahak/core-ui/dist/components/Typography";
import Chip from "@vahak/core-ui/dist/components/Chip";
import COLORS from "@vahak/core-ui/dist/constants/colors";
import { DotSymbol, Rupee } from "@vahak/core-ui/dist/constants";
import Button from "@vahak/core-ui/dist/components/Button";
import { getPriceString } from "@vahak/core-ui/dist/methods/common";
import { PAYMENT_TYPE, PAYMENT_TYPE_MAP } from "@vahak/core/dist/constants";
import IfCanAccess from "../../admin/IfCanAccess/IfCanAccess";
import { ADMIN_OPERATION } from "../../../constants/adminOperation";
import { useCsDashboard } from "@vahak/core/dist/hooks/useCsDashboard";
import AssuredLoadBestPrice from "@vahak/core/dist/icons/ribbon-best-price.svg";

interface ExpectedPriceAndBidNowProps {
    id?: string;
    onBidNowClick?: () => void;
    expectedPrice?: number;
    paymentType: number;
    advanceAmount?: number;
    pricePerTonPerKm?: number;
    additionalCharge?: number;
    isBestPrice?: boolean;
}

const ExpectedPriceAndBidNow = ({
    id,
    onBidNowClick,
    expectedPrice,
    paymentType,
    advanceAmount,
    pricePerTonPerKm,
    additionalCharge,
    isBestPrice
}: ExpectedPriceAndBidNowProps) => {
    const { checkPermission } = useCsDashboard();
    return (
        <div className={Styles.main}>
            <Flex className={Styles.container} alignItems="center" justifyContent="space-between">
                <Flex flexDirection="column" gap={6} justifyContent="center">
                    <div className={Styles.expectedAmount}>
                        <Flex gap={5} id={`${id}--expectedPrice`}>
                            <Typography color={COLORS.GREY_1000} size="m" weight="semibold">
                                {expectedPrice ? getPriceString(expectedPrice) : "--"}
                            </Typography>
                            {isBestPrice ? (
                                <AssuredLoadBestPrice />
                            ) : (
                                <>
                                    {!!expectedPrice && (
                                        <Chip color={"grey"} filled radius={30} size={"auto"} padded={true}>
                                            <Typography size="xs" weight="semibold">
                                                Expected rate
                                            </Typography>
                                        </Chip>
                                    )}
                                </>
                            )}
                        </Flex>
                    </div>
                    <VerticalFlex>
                        <div className={Styles.expectedAmount}>
                            <Flex id={`${id}--pricePerTonPerKm`}>
                                <Typography color={COLORS.GREY_600} size="xs" weight="semibold">
                                    (
                                    {paymentType === PAYMENT_TYPE.Advance
                                        ? `${advanceAmount}% ${PAYMENT_TYPE_MAP[paymentType]}`
                                        : PAYMENT_TYPE_MAP[paymentType]}{" "}
                                    {pricePerTonPerKm ? (
                                        <>
                                            <Typography color={COLORS.GREY_300} size="xs">
                                                {DotSymbol}
                                            </Typography>{" "}
                                            {getPriceString(pricePerTonPerKm)} / tonne-km
                                        </>
                                    ) : (
                                        <></>
                                    )}
                                    )
                                </Typography>
                            </Flex>
                        </div>
                        <div className={Styles.expectedAmount}>
                            <Typography color={COLORS.GREY_600} size="xs">
                                {!!additionalCharge ? (
                                    <>(Additional Charges {getPriceString(additionalCharge)})</>
                                ) : (
                                    <>(Inclusive of all charges)</>
                                )}
                            </Typography>
                        </div>
                    </VerticalFlex>
                </Flex>

                {!!onBidNowClick && (
                    <IfCanAccess action={""} allowCustomer>
                        <div className={Styles.bidNowBtn}>
                            <Button isRounded width={100} onClick={onBidNowClick} id={`${id}--bidNow`}>
                                <Typography size="sm">Bid Now</Typography>
                            </Button>
                        </div>
                    </IfCanAccess>
                )}
            </Flex>
        </div>
    );
};

export default ExpectedPriceAndBidNow;
